import { ErrorSharp } from '@mui/icons-material';
import styles from './warning.module.css';

const Warning = ({ warning, warningState }: { warning: string; warningState: string }) => {
  return (
    <div className={styles.warningContainer} data-state={warningState}>
      <ErrorSharp />
      <p>{warning}</p>
    </div>
  );
};

export default Warning;
