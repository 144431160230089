import { useState, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { ContactShadows } from '@react-three/drei';
import Audi from './objects/cars/Audi';
import Swatch from './components/swatch/swatch';
import LightingManager from './managers/lighting';
import Camera from './managers/camera';
import SwipeTip from './components/ui/swipeTip';
import Header from './components/header/header';
import Splash from './components/splash/splash';

import { useMachine } from '@xstate/react';
import { AppMachine } from './App.machine';
import Warning from 'components/warning/warning.component';

const App = () => {
  const [current, send] = useMachine(AppMachine);
  const splashRef = useRef<HTMLInputElement | null>(null);
  const [warningState, setWarningState] = useState('show');

  const { context: ctx } = current;

  return (
    <>
      <Header
        {...{ current }}
        setLightMode={(e: any) => send('SET_LIGHTMODE', { lightMode: e.lightMode })}
      />
      <Splash {...{ splashRef, setWarningState }} />
      <div id="canvas__container" data-lightmode={ctx.lightMode}>
        <Canvas camera={{ position: [2, 1, 2] }}>
          <Camera />
          <LightingManager {...{ current }} />
          <Audi {...{ current }} />
          <ContactShadows
            opacity={1}
            scale={20}
            blur={1}
            far={80}
            resolution={256}
            color="#000000"
            frames={1}
          />
        </Canvas>
      </div>
      <SwipeTip {...{ current }} />
      <Swatch
        {...{ current }}
        setCarColor={(e: any) => send('SET_CARCOLOR', { carColor: e.carColor })}
      />
      <Warning
        warning={`It appears you are trying to view this demo with a smaller device. Whilst every measure
            has been taken to ensure this demo works on smaller devices it's best enjoyed on devices 
            > 1024px.`}
        {...{ warningState }}
      />
    </>
  );
};

export default App;
