import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import { swatchColors } from '../../utils/colors';
import { ColorLens } from '@mui/icons-material';

import Tooltip from '../tooltip/tooltip.component';
import styles from './swatch.module.css';

interface ISwatchProps {
  setCarColor: Function;
  current: Record<string, any>;
}

const Swatch: React.FC<ISwatchProps> = ({ setCarColor, current }) => {
  const { context: ctx } = current;
  const [colorVisibility, setColorVisibility] = useState<boolean>(false);
  const transitions = useTransition(swatchColors, {
    initial: { opacity: 0, marginLeft: '-24px' },
    enter: { opacity: 1, marginLeft: '0px' },
    delay: 5000,
    trail: 350,
    config: {
      duration: 400,
    },
  });

  const handleClick = (newColor: string) => {
    setCarColor({ carColor: newColor });
  };

  return (
    <div
      className={styles.swatchContainer}
      data-show={colorVisibility}
      data-lightmode={ctx.lightMode}
    >
      <button className={styles.colorButton} onClick={() => setColorVisibility(!colorVisibility)}>
        <ColorLens sx={{ color: ctx.lightMode === 'light' ? 'black' : 'white', fontSize: 32 }} />
      </button>
      <div className={styles.swatchHolder}>
        {transitions((style, swatch) => (
          <animated.div
            key={`swatch-${swatch.id}`}
            className={styles.swatchItem}
            onClick={() => handleClick(swatch.hex)}
            {...{ style }}
          >
            <div className={styles.tooltipHolder}>
              <Tooltip tip={swatch.displayName} />
            </div>
            <span
              className={styles.swatchItemColor}
              style={{ backgroundColor: `#${swatch.hex}` }}
            ></span>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default Swatch;
