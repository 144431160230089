import styles from './tooltip.module.css';

const Tooltip = ({ tip }: { tip: string }) => {
  return (
    <div className={styles.tooltipContainer}>
      <p className={styles.tooltipMessage}>{tip}</p>
    </div>
  );
};

export default Tooltip;
