import { Swipe } from '@mui/icons-material';
import Tooltip from '../tooltip/tooltip.component';
import styles from './swipeTip.module.css';

const SwipeTip = ({ current }: { current: Record<string, any> }) => {
  const { context: ctx } = current;
  return (
    <span className={styles.swipeTip} data-lightmode={ctx.lightMode}>
      <Swipe sx={{ color: ctx.lightMode === 'light' ? 'black' : 'white', fontSize: 32 }} />
      <div className={styles.tooltipHolder}>
        <Tooltip tip={`Swipe to rotate`} />
      </div>
    </span>
  );
};

export default SwipeTip;
