import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

const Camera = () => {
    const { camera, gl } = useThree();
    useEffect(
      () => {
        const controls = new OrbitControls(camera, gl.domElement);
  
        controls.maxDistance = 4;
        controls.minDistance = 3;
        controls.enablePan = false;
        controls.maxPolarAngle = Math.PI / 2.4;
        controls.minPolarAngle = 0.5;

        return () => {
          controls.dispose();
        };
      },
      [camera, gl]
    );
    return null;
  };

export default Camera