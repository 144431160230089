import { MouseEventHandler } from 'react';
import { LightMode, DarkMode } from '@mui/icons-material';
import styles from './lightSwitch.module.css';

const LightSwitch = ({
  current,
  setLightMode,
}: {
  current: Record<string, any>;
  setLightMode: Function;
}) => {
  const { context: ctx } = current;
  const handleClick = () => {
    setLightMode({ lightMode: ctx.lightMode === 'light' ? 'dark' : 'light' });
  };

  return (
    <button className={styles.lightswitch} onClick={handleClick} data-lightmode={ctx.lightMode}>
      <LightMode fontSize="large" />
      <DarkMode fontSize="large" sx={{ color: 'white' }} />
    </button>
  );
};

export default LightSwitch;
