import { useState, LegacyRef } from 'react';
import { ArrowForwardSharp, ErrorSharp } from '@mui/icons-material';
import styles from './splash.module.css';

const Splash = ({
  splashRef,
  setWarningState,
}: {
  splashRef: LegacyRef<HTMLDivElement>;
  setWarningState: (value: string) => void;
}) => {
  const [splashState, updateSplash] = useState('open');

  const btnClick = () => {
    setWarningState('remove');
    updateSplash('closed');
  };

  return (
    <div className={styles.splashContainer} data-status={splashState}>
      <div ref={splashRef} className={styles.splashContent}>
        <h2 className={styles.splashHeading}>R3F Car Configurator</h2>
        <p>This demo is intended for research purposes and as such is not for commercial use.</p>
        <p>
          In this demo I have loaded a .glb model via an export from the pmdr gltf to jsx&nbsp;
          <a href="https://gltf.pmnd.rs/" target="_blank">
            converter
          </a>
          . The model itself was downloaded on&nbsp;
          <a
            href="https://www.cgtrader.com/free-3d-models/car/sport-car/2020-audi-rs-6-avant"
            target="_blank"
          >
            CGTrader
          </a>
          &nbsp;and slightly modified by myself to allow for colour changing and tweaks to the
          materials.
        </p>
        <small>This demo is in no way affiliated, sponsored, nor endorsed by Audi.</small>
        <small>
          Demo created by&nbsp;
          <a href="https://ratcliffe.digital" target="_blank">
            Gary Ratcliffe
          </a>
          &nbsp; on behalf of&nbsp;
          <a href="https://greatstate.co" target="_blank">
            Great State
          </a>
          .
        </small>
        <button className={styles.splashCTA} onClick={btnClick}>
          <ArrowForwardSharp />
        </button>
      </div>
    </div>
  );
};

export default Splash;
