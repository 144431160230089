import { useRef } from 'react';

const LightingManager = ({ current }: { current: Record<string, any> }) => {
  const { context: ctx } = current;
  const lightRefOne = useRef<any>();
  const lightRefTwo = useRef<any>();
  return (
    <>
      <ambientLight intensity={ctx.lightMode === 'white' ? 0.3 : 0.1} />
      {ctx.lightMode === 'light' && (
        <>
          <directionalLight
            ref={lightRefOne}
            color="#FFF"
            position={[3, 1, 2]}
            shadow-mapSize-width={2048}
            shadow-mapSize-height={2048}
            castShadow
          />
          <directionalLight
            ref={lightRefTwo}
            color="#FFF"
            position={[-3, 1, -2]}
            shadow-mapSize-width={2048}
            shadow-mapSize-height={2048}
            castShadow
          />
        </>
      )}
      {ctx.lightMode === 'dark' && (
        <>
          <spotLight
            ref={lightRefTwo}
            color="#FFF"
            position={[-3, 1, -2]}
            shadow-mapSize-width={2048}
            shadow-mapSize-height={2048}
            castShadow
          />
          <spotLight
            ref={lightRefOne}
            color="#FFF"
            position={[3, 1, 2]}
            shadow-mapSize-width={2048}
            shadow-mapSize-height={2048}
            castShadow
          />
        </>
      )}
    </>
  );
};

export default LightingManager;
