// @ts-nocheck
import React, { useRef, useState, useEffect, LegacyRef } from 'react';
import { useSpring } from '@react-spring/core';
import { a } from '@react-spring/three';
import { useGLTF } from '@react-three/drei';
import { Interpolation } from 'react-spring';

const MaterialCarPaint = ({
  actualColor,
  mutMaterials,
  color,
}: {
  actualColor: string;
  mutMaterials: any;
  color: Interpolation<number, `#${any}`>;
}) => {
  return (
    <a.meshPhysicalMaterial
      {...mutMaterials['CARI PAINT']}
      color={color || actualColor}
      metalness={0}
      clearcoat={1}
      reflectivity={1}
      clearcoatRoughness={0}
      roughness={0}
    />
  );
};

const MaterialGlass = ({ mutMaterials }: { mutMaterials: any }) => {
  return (
    <meshPhysicalMaterial
      {...mutMaterials['Glass']}
      color={0x323334}
      roughness={0.1}
      clearcoat={1}
      metalness={0.9}
      reflectivity={0.2}
      refractionRatio={0.985}
    />
  );
};

const Audi = ({ current }: { current: Record<string, any> }) => {
  const { context: ctx } = current;
  const [actualColor, setNewColor] = useState('FFFFFF');
  const [active, setActive] = useState(0);

  const wheelRef = useRef<any>(null);
  const group = useRef<any>(null);
  const { nodes, materials } = useGLTF('/assets/objects/Audi_RS_6_Avant_Applied__compressed.glb');
  let mutMaterials = materials;

  const { spring } = useSpring({
    spring: active,
    config: { duration: 350 },
    onRest: () => {
      setNewColor(ctx.carColor);
      setActive(0);
    },
  });
  const color = spring.to([0, 1], [`#${actualColor}`, `#${ctx.carColor}`]);

  useEffect(() => {
    if (ctx.carColor !== actualColor) {
      setActive(1);
    }
  }, [ctx, actualColor]);

  return (
    <group ref={group} dispose={null}>
      <group name="windows">
        <mesh name="side-windows" castShadow receiveShadow geometry={nodes.Plane002.geometry}>
          <MaterialGlass {...{ mutMaterials }} />
        </mesh>
        <mesh name="windscreen" castShadow receiveShadow geometry={nodes.Plane024.geometry}>
          <MaterialGlass {...{ mutMaterials }} />
        </mesh>
        <mesh namer="rear-window" castShadow receiveShadow geometry={nodes.Plane028.geometry}>
          <MaterialGlass {...{ mutMaterials }} />
        </mesh>
      </group>
      <mesh castShadow receiveShadow geometry={nodes.Plane001.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Plane009_2.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane008.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane009.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane009_1.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane012.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane011.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane013.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane014.geometry}
        material={mutMaterials['Indisde Front Grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane015.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane016.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane017.geometry}
        material={mutMaterials['Indisde Front Grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane004.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Plane010.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane020.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane022.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane021.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane025.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane026.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane029.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle003.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane030.geometry}
        material={mutMaterials['Indisde Front Grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane031.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane032.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane033.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane035.geometry}
        material={mutMaterials.Plastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane036.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane045.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane043.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane044.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane046.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane047.geometry}
        material={nodes.Plane047.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane048.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane049.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane050.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane054.geometry}
        material={mutMaterials.Bright_front_headlight}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane055.geometry}
        material={mutMaterials.Bright_front_headlight}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane056.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane057.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane059.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane072_1.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane072_2.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane060.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane061.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane067.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane052.geometry}
        material={mutMaterials['Material.006']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane053.geometry}
        material={mutMaterials['Material.006']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Plane006.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane018.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane087.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle001.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle006.geometry}
        material={mutMaterials['RIM BRIGHT']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle007.geometry}
        material={nodes.Circle007.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle009.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle010.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle011.geometry}
        material={mutMaterials['carbon disk brake']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle012.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle013.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane040.geometry}
        material={mutMaterials['RIM BRIGHT']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder003.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane089.geometry}
        material={mutMaterials['Brake Kit']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Plane003.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Plane095.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane099.geometry}
        material={mutMaterials.Plastic}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane062.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane027.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle005.geometry}
        material={mutMaterials['tire.001']}
        ref={wheelRef}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane041.geometry}
        material={mutMaterials['Small Red lamp (replaced)']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle014.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle015.geometry}
        material={mutMaterials['RIM BRIGHT']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane068.geometry}
        material={mutMaterials.Bright_front_headlight}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane069.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane070.geometry}
        material={mutMaterials.Bright_front_headlight}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane094.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane098.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Plane105.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Plane107.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle023.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Plane037.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Plane104.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane109.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane111.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane114.geometry}
        material={mutMaterials['Material.001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle002.geometry}
        material={mutMaterials['RIM BRIGHT']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle004.geometry}
        material={nodes.Circle004.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle008.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle016.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle017.geometry}
        material={mutMaterials['carbon disk brake']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle018.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle019.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane091.geometry}
        material={mutMaterials['RIM BRIGHT']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane093.geometry}
        material={mutMaterials['Brake Kit']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle020.geometry}
        material={mutMaterials['tire.001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle021.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle022.geometry}
        material={mutMaterials['RIM BRIGHT']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube_1.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube_2.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube014_1.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube014_2.geometry}
        material={mutMaterials['Front DRL']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={mutMaterials['Indisde Front Grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        material={mutMaterials.MIrror}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011.geometry}
        material={mutMaterials['Mirror Lamp']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Cube012.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Cube014.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve009_1.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve009_2.geometry}
        material={mutMaterials['RIM BRIGHT_red']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve009_3.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve009_4.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve024.geometry}
        material={mutMaterials['Front grill']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve024_1.geometry}
        material={mutMaterials['RIM BRIGHT_red']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane128_1.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane128_2.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane019.geometry}
        material={mutMaterials['Material.002']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Plane023.geometry}>
        <MaterialCarPaint {...{ actualColor, mutMaterials }} color={color} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane034.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane125.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane125_1.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane042.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane063.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane122_1.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane122_2.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane065.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane066.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane071.geometry}
        material={mutMaterials['Rear LIght_clear']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane072.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane073.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane074.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane075.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane076.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane077.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane078.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane079.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane080.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane081.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane082.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane083.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane084.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane085.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane086.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane088.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane090.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane092.geometry}
        material={mutMaterials.Rear_secondary_emission}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane097.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane100.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane101.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane102.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane103.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane108.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane110.geometry}
        material={mutMaterials['Material.006']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane112.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane113.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane119.geometry}
        material={mutMaterials.Chrome_Light}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane122.geometry}
        material={mutMaterials['Material.005']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane124.geometry}
        material={nodes.Plane124.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane127.geometry}
        material={mutMaterials['RIM DARK']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane128.geometry}
        material={mutMaterials.Rear_main_emission}
      />
    </group>
  );
};

export default Audi;
useGLTF.preload('/assets/objects/Audi_RS_6_Avant_Applied__compressed.glb');
