import LightSwitch from '../../components/ui/lightSwitch';
import { ReactComponent as AudiLogo } from '../../assets/Audi_Rings_bl-RGB.svg';
import styles from './header.module.css';

const Header = ({
  current,
  setLightMode,
}: {
  current: Record<string, any>;
  setLightMode: Function;
}) => {
  const { context: ctx } = current;
  return (
    <header className={styles.header} data-lightmode={ctx.lightMode}>
      <div className={styles.audiLogo}>
        <AudiLogo />
      </div>
      <h1 className={styles.heading}>Audi RS6 Avant</h1>
      <div className={styles.lightSwitchHolder}>
        <LightSwitch {...{ current, setLightMode }} />
      </div>
    </header>
  );
};

export default Header;
