import { createMachine, assign } from 'xstate'

interface AppContext {
  lightMode: string;
  carColor: string;
};

type AppEvent = 
| { type: 'SET_CARCOLOR'; carColor: string; }
| { type: 'SET_LIGHTMODE'; lightMode: string; };

type AppTypestate = {
  value: 'idle';
  context: AppContext;
};


export const AppMachine = createMachine<
AppContext,
AppEvent,
AppTypestate
>({
    id: 'carConfig',
    initial: 'idle',
    context: {
      carColor: 'd0d2d1',
      lightMode: 'light'
    },
    states: {
      idle: {
        on: {
          SET_CARCOLOR: {
            actions: [assign({ carColor: (ctx, e) => e.carColor})],
          },
          SET_LIGHTMODE: {
            actions: [assign({ lightMode: (ctx, e) => e.lightMode})],
          },
        }
      }
    }
  },
);